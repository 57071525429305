.front-page{
	padding: 10px 15px 0;

	.featured-news{
		display: block;

		.carousel{
			width: 100%;
		}

		.news-img {
			height: 500px; 
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
			position: relative;
			width: 100%;
		}

		.news-content{
			position: absolute;
			bottom: 0;
			left: 0;
			color: #fff;
			padding: 0px 15px;
			width: 100%;

			.self-content{
				margin-bottom: 30px;

				a{
					color: #fff;
					
					h3{
						padding: 20px 25px;
						margin-bottom: 0;
						background: #000;
						max-width: 50%;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}
				}
				
				span{
					padding: 20px 55px 20px 25px;
					background: #000;
				}
			}

			.news{
				background-color: rgba(0, 0, 0, 0.76);
				cursor: pointer;

				span{
					text-transform: uppercase;
					font-size: 14px;
				}

				h5{
					margin-bottom: 0;
					padding: 5px 0 0px;
					overflow: hidden;
					display: -webkit-box;
					-webkit-line-clamp: 3;
					width: 100%;
				}
			}

			.active{
				background: $brand-red;
			}
		}
	}

	.headliners{
		h3{
			border-bottom: 2px solid $brand-red;
			padding-bottom: 5px;
		}

		.news-content{
			h5{
				overflow: hidden;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical; 
				margin-bottom: 0;
			}
			span{
				font-weight: 700;
				text-transform: uppercase;
				font-size: 13px;
			}
			p{
				overflow: hidden;
				display: -webkit-box;
				-webkit-line-clamp: 4;
				-webkit-box-orient: vertical; 
				font-size: 15px;
			}
		}
	}

	.sub-featured-news{
		background-color:#e8e8e8;
		padding: 40px 20px;
		margin-top: 20px;

		.thumb-img{
			height: 150px;
			width: 100%;
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
		}

		.news-content{
			margin-top: 10px;

			.post-cat-date{
				font-weight: bold;
				text-transform: uppercase;
			}

			a{
				color: #fff;

				&:hover{
					color: $brand-red;
				}
				h5{
					overflow: hidden;
					display: -webkit-box;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical; 
					font-weight: bold;
					min-height: 44px;
					color: $brand-red;
				}
			}
			
			p{
				margin-top: 15px;
				font-size: 14px;
				overflow: hidden;
				display: -webkit-box;
				-webkit-line-clamp: 3;
				-webkit-box-orient: vertical; 
			}
		}
	}

	/*.other-news{
		padding: 0 20px;

		
		.news{
			background-color: #fff;

			//&:not(:last-of-type){
				margin-right: 20px;
			//}
		}
	}*/

	.more-news-content{
		margin-top: 20px;

		.more-news-label{
			color: $brand-red;
			font-weight: bold;
			font-size: 20px;
			text-transform: uppercase;
			border-bottom: 2px solid $brand-darkGrey;
		}

		.more-news-content{
			margin: 30px 0;

			.thumb-img{
				height: 150px;
				width: 100%;
				background-position: 50%;
				background-repeat: no-repeat;
				background-size: cover;
			}

			.news-content{
				.post-cat-date{
					font-weight: bold;
					text-transform: uppercase;
				}

				a{
					color: #fff;

					&:hover{
						color: $brand-red;
					}
					h5{
						overflow: hidden;
						display: -webkit-box;
						-webkit-line-clamp: 2;
						-webkit-box-orient: vertical; 
						font-weight: bold;
						min-height: 44px;
					}
				}
				
				p{
					margin-top: 15px;
					font-size: 14px;
					overflow: hidden;
					display: -webkit-box;
					-webkit-line-clamp: 3;
					-webkit-box-orient: vertical; 
				}
			}
		}

		.sidebar-menu{
			.menu{
				display: inline-block;
				width: 32%;
				text-transform: uppercase;
				font-weight: bold;
				padding: 5px 10px;
				cursor: pointer;
			}
			.active{
				background-color: #1f1f1f;
				color: $brand-red;
			}
		}

		.sidebar-content{
			margin: 20px 0;

			.content{
				&:not(:first-of-type){
					display: none;
				}

				.headlines, .trending{
					&:not(:last-of-type){
						margin-bottom: 20px;
					}

					.thumb-img{
						height: 75px;  
						width: 100%;
						background-position: 50%;
						background-repeat: no-repeat;
						background-size: cover;
					}

					.news-content{
						.post-cat-date{
							font-weight: bold;
							text-transform: uppercase;
							font-size: 13px;
						}

						a{
							color: #fff; 
		
							&:hover{
								color: $brand-red;
							}
							h5{
								overflow: hidden;
								display: -webkit-box;
								-webkit-line-clamp: 2;
								-webkit-box-orient: vertical; 
								font-weight: bold;
								min-height: 44px;
								font-size: 16px;
							}
						}
					}
				}
			}
		}
	}

	.sidebar-ads{
		display: block!important;
		img{
			width: 100%;
			height: 100%;
		}
	}

	.ads-img-928-90{
		margin: 20px 0;

		img{
			width: 920px;
			height: 90px;

			@media screen and (max-width: 991px) {
				width: 100%!important;
				height:100%!important;
			}
		}
	}
}

.single-post-container{
	padding: 30px;

	.post-cat-date{
		font-weight: 700;
		font-size: 18px;

		span{
			color: $brand-red;
		}
	}

	.post-title{
		font-size: 38px;
		font-weight: bold;
	}

	img{
		width: 100%;
		padding: 10px 0;
		height: 100%;
	}

	.post-conte{
		padding: 10px 0;
	}
}

.tiyu8898-sidebar{
	padding: 30px 0;

	.recent-posts, .similar-posts{
		margin-bottom: 20px;

		.block-title{
			background-color: $brand-darkGrey;
			color: #fff;
			padding: 7px 5px;
			font-size: 18px;
		}
		.posts-content{
			.posts{
				//&:not(:last-of-type){
					margin: 20px 0;
				//}

				.thumb-img{
					height: 75px;  
					width: 100%;
					background-position: 50%;
					background-repeat: no-repeat;
					background-size: cover;
				}

				.news-content{
					.post-cat-date{
						font-weight: bold;
						text-transform: uppercase;
						font-size: 13px;
					}

					a{
						color: #fff;
	
						&:hover{
							color: $brand-red;
						}
						h5{
							overflow: hidden;
							display: -webkit-box;
							-webkit-line-clamp: 2;
							-webkit-box-orient: vertical; 
							font-weight: bold;
							//min-height: 44px;
							font-size: 16px;
						}
					}
				}
			}
		}
	}
}

.arhive-page{
	padding: 40px 50px;
	.blog-post-items{

		margin-bottom: 20px;

		.thumb-img{
			height: 250px;
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
			position: relative;
		}

		.post-content{
			padding: 5px 20px;

			.post-title{
				color: $brand-red;
				font-weight: bold;
				margin-bottom: 0;
			}

			.post-cat-date{
				font-weight: bold;
			}

			.post-content{
				overflow: hidden;
				display: -webkit-box;
				-webkit-line-clamp: 5;
				-webkit-box-orient: vertical; 
				padding: 0!important;
				margin-top: 10px;
				//min-height: 44px;
			}

			.readMore-btn{
				background-color: $brand-red;
				color: #fff;
    			padding: 5px 15px;
			}
		}
	}
}