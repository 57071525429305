footer{
	color: $brand-font-color;

	.footer-columns{
		padding: 0 0 30px 0;

		.footer-divs{
			&:not(:last-of-type){
				border-right: 2px solid $brand-darkGrey;
			}

			.brand{
				font-size: 45px;
			}
			.social-icons{
				margin: 10px 0 15px;
		
				a{
					border: 2px solid $brand-font-color;
					border-radius: 50%;
					padding: 8px 12px;
		
					&:not(:first-of-type){
						margin-left: 10px;
					}
		
					i{
						font-size: 20px;
						color: $brand-font-color!important;
					}
				}
			}

			label{
				font-weight: bold;
			}
		}

		.quick-links{
			padding: 20px 15px;

			a{
				display: block;
				margin-top: 10px;
				font-size: 15px;
				color: $brand-font-color;
			}

			@media screen and (max-width: 575px) {
				text-align: center;
			}
		}

		.feedback{
			padding: 20px 15px;
			text-align: center;

			label{
				float: left;

				@media screen and (max-width: 575px) {
					display: block;
					float: unset!important;
				}
			}

			.feedback-btn{
				color: #fff;
				background-color: $brand-red;
				display: inline-block;
				width: 80%;
				padding: 18px 20px;
				font-size: 13px;
				margin-top: 35px;
			}

			@media screen and (max-width: 575px) {
				text-align: center;
			}
		}
	}

	.copyright{
		background-color: $brand-darkGrey;
		text-align: center;
		color: #fff;
		padding: 15px 0;
	}
}

/*footer{
	background-color: #00143c;
    color: #fff;
	font-size: 12px;
	box-shadow: inset 0 15px 15px rgba(0,0,0,0.1), 0 -5px rgba(0,48,86,0.8);
	padding: 3.75em 0;
	
	a{ 
		color: #fff;
		
		&:hover{
			color: #fff;
		}
	}
	
	.payment-methods{
		margin-top: 20px;
		
		.payments{
			list-style: none;
			padding: 0;
			float: left;
			margin: 0 auto;
			
			li{
				width: 56px;
				background: url(../images/badge.png) no-repeat;
				background-size: 1700px;
				height: 40px;
				display: inline-block;
				border-radius: 2px;
				box-shadow: 0 1px rgba(0,0,0,0.4);
				float: left;
				margin-right: 5px;
			}
			
			.paypal{ background-position: 2px 0; }
			.visa{ background-position: -51px 0; }
			.mastercard{ background-position: -104px 0; }
			.bpay{ background-position: -211px 0; }
			.skrill{ background-position: -157px 0; }
			.poli{ background-position: -316px 0; }
			.mpass{ width: 81px!important; background-position: -373px 0; }
		}
		
		.social-media{
			list-style: none;
			padding: 0;
			float: right;
			margin: 30px auto;
			
			li{
				width: 33px;
				background: url(../images/badge.png) no-repeat;
				background-size: 1700px;
				height: 40px;
				display: inline-block;
				border-radius: 2px;
				box-shadow: 0 1px rgba(0,0,0,0.4);
				float: left;
				margin-right: 5px;
			}
			
			.facebook{ background-position: -457px 0; }
			.twitter{ background-position: -491px 0; }
			.youtube{ background-position: -525px 0; }
			.google-plus{ background-position: -559px 0; }
			.instagram{ background-position: -594px 0; }
		}
	}
	
	.betting-operator{
		border-top: 1px dotted rgba(255,255,255,0.2);
		
		p{ padding-top: 20px; }
		
		.operators{
			list-style: none;
			padding: 0;
			float: left;
			margin: 0 auto;
			
			li{
				width: 56px;
				background: url(../images/badge.png) no-repeat;
				background-size: 1700px;
				height: 40px;
				display: inline-block;
				border-radius: 2px;
				box-shadow: 0 1px rgba(0,0,0,0.4);
				float: left;
				margin-right: 5px;
			}
			
			.ffa{ background-position: -1339px 0; }
			.nball{ width: 87px!important; background-position: -1392px 0; }
			.pga{ width: 86px!important; background-position: -1476px 0; }
			.rugb{ background-position: -1144px 0; }
			.afl{ background-position: -1040px 0; }
			.nrl{ background-position: -1092px 0; }
			.crick{ width: 67px; background-position: -966px 0; }
			.tenn{ background-position: -903px 0; }
		}
	}
	
	.betting-provider{
		
		p{ padding-top: 10px; }
		
		.providers{
			list-style: none;
			padding: 0;
			float: left;
			margin: 0 auto;
			
			li{
				width: 56px;
				background: url(../images/badge.png) no-repeat;
				background-size: 1700px;
				height: 40px;
				display: inline-block;
				border-radius: 2px;
				box-shadow: 0 1px rgba(0,0,0,0.4);
				float: left;
				margin-right: 5px;
			}
			
			.auopen{ background-position: -1615px 0; width: 85px; }
			.mvall{ width: 84px!important; background-position: -1254px 0; }
		}
	}
	
	.scroll-to{
		text-align: center;
		padding: 90px 0;
		
		.scrollUp{
			font-size: 20px;
			background: rgba(255,255,255,0.1);
			padding: 15px 40px;
		}
	}
}
*/