.banner {
	display: -webkit-box;
	
	@media screen and (max-width: 991px) {
		display: none;
	}
	
	/*.brand{
		font-family:'sf-sports-night','Open Sans',Arial,Helvetica,sans-serif;
		color: $brand-red;
		font-size: 55px;

		&:hover{
			text-decoration: none;
		}
	}*/

	.ads-img{
		display: inline-block;
		width: 468px;
		height: 60px;
		float: right;
		margin-top: 10px;

		img{
			width: 100%;
			height: 100%;
		}
	}

	.social-icons{
		float: right;
		margin-top: 30px;

		a{
			border: 2px solid #000;
			border-radius: 50%;
			padding: 8px 12px;

			&:not(:first-of-type){
				margin-left: 10px;
			}

			i{
				font-size: 20px;
				color: #000!important;
			}
		}
	}
}

.whablog-menus{
    color: #fff;
    height: 40px;
		
	nav{
		background: $brand-darkGrey;

		ul {
			padding: 0;
			margin: 0;
			list-style: none;
			position: relative;
			
			li {
				display:inline-block;
				
				&:hover > .parent-ul {
					display:inherit;
				}
				
				a{
					font-weight: bold;
				}
			}
			
			a {
				display:block;
				padding:0 15px;	
				color:#FFF;
				font-size:16px;
				line-height: 40px;
				text-decoration:none;
				text-transform: uppercase;
				
				&:hover{
					color: $brand-red;
				}
			}
			
			ul {
				display: none;
				position: absolute; 
				top: 40px; /* the height of the main nav */
				z-index: 100000;
				
				li {
					min-width:170px;
					float:none;
					display:list-item;
					position: relative;
					border-bottom: 1px solid #00143C;
				}
				
				/*ul li {
					position: relative;
					top:-40px; 
					left:170px;
				}*/
			}
		}
	
		/* Change this in order to change the Dropdown symbol */
		.parent-li > .parent-tag:after { 
			content:  "\f107"; 
			font-family: FontAwesome;
			font-style: normal;
			font-weight: normal;
			padding-left: 10px;
			font-size: 15px;
		}
		.parent-li:hover > .parent-tag { 
			color: yellow;
		}
		.parent-li:hover > .parent-tag:after { 
			content:  "\f106"; 
			font-family: FontAwesome;
			font-style: normal;
			font-weight: inherit;
			padding-left: 10px;
			font-size: 15px;
		}
		.parent-li > .parent-tag:only-child:after { content: ''; }
		
		.parent-ul{
			a { 
				font-size: 14px;
				font-weight: bold; 
			}
		}
		
		.accordion dl{ margin:0; }
		.accordion dt{ border-bottom: 1px solid #00143C }
		.accordion dd,
		.accordion__panel {
		   background-color:#00143c;
		   font-size:1em;
		   line-height:1.5em; 
		   margin: 0;
		}

		.accordionTitle,
		.accordion__Heading {
			background-color:#1d335e; 
			font-weight:400; 
			display:block;
			text-decoration:none;
			color:#fff;
			transition:background-color 0.5s ease-in-out;
			font-size: 14px;
				
			&:before {
				content:  "\f107"; 
				font-family: FontAwesome;
				float:right; 
				transition: transform 0.3s ease-in-out;
				margin-left: 30px;
			}
			&:hover {
				background-color:#1d335e;
			}
		}
		.accordionTitleActive, 
		.accordionTitle.is-expanded {
			&:before {
			 
			  transform:rotate(-180deg);
			}
		}
		.accordionItem {
			height:auto;
			overflow:hidden; 
			//SHAME: magic number to allow the accordion to animate
			
			max-height:50em;
			transition:max-height 1s;   
		 
			a{ font-size: 14px;font-weight: normal; }
			
			@media screen and (min-width:48em) {
				 max-height:15em;
				transition:max-height 0.5s
				
			}
		}
 
		.accordionItem.is-collapsed {
			max-height:0;
		}
		.no-js .accordionItem.is-collapsed {
		  max-height: auto;
		}
		/*.animateIn {
			 animation: accordionIn 0.45s normal ease-in-out both 1; 
		}
		.animateOut {
			 animation: accordionOut 0.45s alternate ease-in-out both 1;
		}*/
		@keyframes accordionIn {
		  0% {
			opacity: 0;
			transform:scale(0.9) rotateX(-60deg);
			transform-origin: 50% 0;
		  }
		  100% {
			opacity:1;
			transform:scale(1);
		  }
		}

		@keyframes accordionOut {
			0% {
			   opacity: 1;
			   transform:scale(1);
			 }
			 100% {
				  opacity:0;
				   transform:scale(0.9) rotateX(-60deg);
			   }
		}
	}
	
	@media screen and (max-width: 991px) {
		display: none;
	}

}

.mobile-view{
    width: 100%;
    z-index: 1;
	top: 0;
		
	@media screen and (min-width: 991px) {
		display: none;
	}

	.wha-mobile{
		background-color: $brand-darkGrey;
		color: #fff;
		padding: 15px 0px;
		display: block;
		
		@media screen and (min-width: 991px) {
			display: none;
		}
		
		.brand{
			font-size: 45px!important;
		}
		
		.action-icons{
			display: inline-block;
			float: right;
			font-size: 25px;
			margin-top: 6px;
			
			.search-icon{
				margin-right: 25px;
			}
		}
		
		.search-box{
			text-align: center;
			position: absolute;
			padding: 0;
			background-color: #00143c !important;
			left: 0;
			top: 0 !important;
			right: 0;
			bottom: 0;
			width: 100%;
			height: 100%;
			margin: 0 !important;
			z-index: 2000;
			border: 0;
			display: none;
			
			.search-icon{
				position: absolute;
				right: 0;
				color: #003056;
				background: transparent;
				top: 48%;
				right: 109px;
				font-size: 20px;
				margin-top: -10px;
				z-index: 1;
			}
			
			form{
				display: block;
				z-index: 0;
				
				input{
					font-size: 15px;
					margin-top: 4px;
					font-weight: 300;
					width: 70%;
					margin-left: 13px;
					border-radius: 100px;
					padding: 11px 15px;
				}
			}
			
			label{
				position: absolute;
				right: 0;
				color: #fff;
				background: transparent;
				top: 39%;
				right: 17px;
				font-size: 20px;
				margin-top: -10px;
				z-index: 1;
			}
		}
	}

	.wha-mobile-menu{
		background-color: #232220;
		color: #fff;
		position: absolute;
		z-index: 1;
		width: 100%;
		margin-top: -1px;

		@media screen and (min-width: 991px) {
			display: none;
		}
		
		nav{
			ul{
				list-style: none;
				padding: 0;
				margin: 0;
				
				li{
					border-bottom: 1px solid #0e0e0d;
					font-size: 22px;
					font-weight: bold;
					padding: 11px 16px;
					
					a{
						color: #fff;
						
						&:active{
							color: $brand-red;;
						}
						
						i{
							float: right;
							padding: 5px;
							font-size: 22px;
						}
					}
				}
				
				.parent-ul{
					background: #041C4C;
					
					li{
						font-size: 18px;
					}
					
					.child-ul{
						background: #00143C;
						
						li{
						    font-weight: normal;
						}
					}
				}
			}
		}
		
		.whablog-social{
			float: none!important;
			width: 100%;
			text-align: center;
			padding: 20px 0;

			label{
				display: block;
			}
		}

		.social-icon{
			font-size: 30px;
			color: #fff;
			margin-right: 5px;
			margin-bottom: 20px;
		}
	}

}
